import { applyMiddleware, combineReducers, compose, legacy_createStore as _createStore, Store } from 'redux';
import thunk, { ThunkAction } from 'redux-thunk';
import { IHttpApi } from '@cian/http-api/shared/http';
import { ILogger } from '@cian/logger/shared';
import { IConfig } from '@cian/config/shared';

import { IOfferInfo } from '../api/offer';
import { TDeviceType } from '../types/device';
import { IAgentCard, IAgentInfoDealInfo } from '../types/agentCard';
import { advicesReducer, IAdvicesData } from './advices';
import { answerFormReducer, IAnswerFormState } from './answer_form';
import { blogsReducer, IBlogsData } from './blogs';
import { chatStatisticsReducer, IChatStatistics } from './chat_statistics';
import { chatReducer, IAgentChatsState } from './chats';
import { ILoginMotivationPopupState, showMotivationPopupReducer } from './login_motivation_popup';
import { modalsReducer, TModalsState } from './modals';
import { offersReducer } from './offers';
import { pushSubscriptionReducer } from './push_subscribed';
import { IQuestionsData, questionsReducer } from './questions';
import { IReviewFormState, reviewFormReducer } from './review_form';
import { TReviewsState, reviewsReducer } from './reviews';
import { ISimplifiedOfferCardPopupsState, simplifiedOfferCardPopupsStateReducer } from './simplified_card_popups';
import { IVacanciesData, vacanciesReducer } from './vacancies';
import { IAbUseExperiment } from '../types/abUseExperiment';
import { IPersonalLeadFormState, personalLeadFormReducer } from './personal_leads_form';
import { IThunkExt, TActions } from '../types/redux';
import { chatModalReducer, IChatModal } from './chat_modal';
import { calltrackingModalReducer, ICalltrackingModal } from './calltracking_modal';

export type IThunkActionCreator<T = void> = ThunkAction<T, IAppState, IContext, TActions>;

export type TEntityType = 'newbuilding' | 'agent';

export type TSiteVersion = 'adaptive' | 'desktop';

export interface IPageData {
  abGroup?: number;
  deviceType: TDeviceType;
  entityId: number;
  entityType: TEntityType;
  path: string;
  subdomain: string;
  siteVersion: TSiteVersion;
}

export interface INotAuthenticatedUser {
  isAuthenticated: false;
  anonymousUserId: string | null;
}

export interface IAuthenticatedUser {
  isAuthenticated: true;
  realtyUserId: number;
  cianUserId: number;
  email: string;
  displayName: string;
  isAgent: boolean;
  avatarUrl: string;
  tariff?: string[];
  permissions: {
    canModerateAnnouncements: boolean;
    canModerateUsers: boolean;
    canViewUsers: boolean;
    canUseHiddenBase: boolean;
  };
}

export type TUser = INotAuthenticatedUser | IAuthenticatedUser;

export interface IAppState {
  abUseExperiments: IAbUseExperiment[];
  modals: TModalsState;
  realtor: IAgentCard;
  pageData: IPageData;
  avatarHost: string;
  loginMotivationPopupOnFavorite: ILoginMotivationPopupState;
  answerForm: IAnswerFormState;
  reviewForm: IReviewFormState;
  reviews: TReviewsState;
  advices: IAdvicesData;
  blogs: IBlogsData;
  questions: IQuestionsData;
  offers: IOfferInfo[];
  personalLeadsForm: IPersonalLeadFormState;
  simplifiedOfferCardPopupsState: ISimplifiedOfferCardPopupsState;
  userAgent: string;
  user: TUser;
  realtorChat: IAgentChatsState;
  vacancies: IVacanciesData;
  chatStatistics: IChatStatistics;
  pushSubscribed: boolean;
  e2eDealInfo: IAgentInfoDealInfo;
  chatModal: IChatModal;
  calltrackingModal: ICalltrackingModal;
}

export interface IContext {
  config: IConfig;
  logger: ILogger;
  httpApi: IHttpApi;
}

export function createStore(context: IContext, preloadedState: IAppState): Store<IAppState, TActions> & IThunkExt {
  const reducer = combineReducers<IAppState, TActions>({
    abUseExperiments: state => state || [],
    advices: advicesReducer,
    answerForm: answerFormReducer,
    avatarHost: (state: string = preloadedState.avatarHost) => state,
    blogs: blogsReducer,
    chatStatistics: chatStatisticsReducer,
    loginMotivationPopupOnFavorite: showMotivationPopupReducer,
    modals: modalsReducer,
    offers: offersReducer,
    pageData: (state: IPageData = preloadedState.pageData) => state,
    personalLeadsForm: personalLeadFormReducer,
    pushSubscribed: pushSubscriptionReducer,
    questions: questionsReducer,
    realtor: (state: IAgentCard = preloadedState.realtor) => state,
    realtorChat: chatReducer,
    reviewForm: reviewFormReducer,
    reviews: reviewsReducer,
    simplifiedOfferCardPopupsState: simplifiedOfferCardPopupsStateReducer,
    user: (state: TUser = preloadedState.user) => state,
    vacancies: vacanciesReducer,
    e2eDealInfo: (state: IAgentInfoDealInfo = preloadedState.e2eDealInfo) => state,
    userAgent: state => state || '',
    chatModal: chatModalReducer,
    calltrackingModal: calltrackingModalReducer,
  });

  //Игнорим строку, потому что линтер ругается, якобы выражение всегда true
  const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

  const store = _createStore<IAppState, TActions, IThunkExt, {}>(
    reducer,
    preloadedState,
    composeEnhancers(applyMiddleware(thunk.withExtraArgument(context))),
  );

  return store;
}
